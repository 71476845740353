import React, { useEffect, useState } from "react";
import { Card, Dropdown, ExpandableCard, Tile } from "@darktrace/ui-components";
import styled from "styled-components";
import dayjs from "dayjs";
import { alertStatus, defaultAlertsTabFilters, now, fiveDaysAgo, checkForPresetMatch, escalationsNavigateProps } from "../utils";
import { useAnalystShifts, usePTNAlerts, useUserOwnRoles, useUserProfileInfo } from "../../../logic/api";
import { useDispatch, useSelector } from "react-redux";
import { useActiveClientId, useQueryParams } from "../../../logic/hooks";
import { setIsSidepanelCollapsed } from "../../../logic/store.js";
import { TileBadge } from "./TileBadge.jsx";
import { createSearchParams, useLocation, useNavigate } from "react-router-dom";
import { ShiftButtonGroup } from "../shared/ShiftButtonGroup.jsx";
import { AnalystList } from "../shared/AnalystList.jsx";

var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

const StyledOverviewTab = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
  height: 100%;
  .time-bar {
    height: 2.8rem;
    .dt-ui-tile__header-text {
      align-items: center;
      display: flex;
      gap: 0.8rem;
    }
  }
  > .dt-ui-card:last-of-type {
    flex-grow: 1;
  }
  .tenant-card-contents {
    display: flex;
    gap: 1.2rem;
    flex-direction: column;
  }
`;

const StyledTile = styled(Tile)`
  height: 3.6rem;
  width: 100%;
  &:not(:last-of-type) {
    margin-bottom: 1.2rem;
  }
  .dt-ui-tile__header-right {
    display: flex;
    align-items: center;
    gap: 0.8rem;
  }
`;

function Time() {
  const [currentTime, setCurrentTime] = useState(dayjs().utc());

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(dayjs().utc());
    }, 1000 * 10);

    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <i className="fas fa-calendar-days"></i>
      {currentTime.utc().format("DD-MM-YYYY")}
      <i className="fas fa-clock"></i>
      {currentTime.utc().format("HH:mm")} (UTC)
    </>
  );
}

export function OverviewTab() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const activeClientId = useActiveClientId();
  const { data: userProfileInfo } = useUserProfileInfo();
  const yourUserId = userProfileInfo?.user?.id;

  const { data: userOwnRoles = [] } = useUserOwnRoles({ clientId: activeClientId });
  const isInternalSOCManager = userOwnRoles.find((role) => role.id === "internal-client-role-cloud-soc-manager");

  const isSidepanelCollapsed = useSelector((state) => state.app.isSidepanelCollapsed);
  const _clients = useSelector((state) => state.app.clients);
  const clients = _clients.map((client) => ({ id: client.id, label: client.label, selected: client.id === activeClientId }));
  const defaultClientId = useSelector((state) => state.app.defaultClientId);
  const defaultClientLabel = _clients.find((client) => client.id === defaultClientId)?.label;
  const { queryParams, updateQueryParams } = useQueryParams({
    defaultQueryParams: { clientId: defaultClientId, ...defaultAlertsTabFilters },
  });

  const status = queryParams.status?.split(",") || null;
  const assignee_id = queryParams.assignee_id?.split(",") || null;

  const status_changed_start_time = queryParams.status_changed_start_time || null;
  const status_changed_end_time = queryParams.status_changed_end_time || null;

  const { data: analystShifts } = useAnalystShifts({ clientId: activeClientId, userId: yourUserId, enabled: !!isInternalSOCManager });
  const { data: alertsInProgress } = usePTNAlerts({ clientId: activeClientId, filters: { status: ["inprogress"] } });
  const { data: alertsNotAssigned } = usePTNAlerts({ clientId: activeClientId, filters: { status: ["new"] } });
  const { data: assignedToMe } = usePTNAlerts({
    clientId: activeClientId,
    filters: { assignee_id: [yourUserId], status: ["inprogress"] },
  });
  const { data: escalatedAlerts } = usePTNAlerts({
    clientId: activeClientId,
    filters: { status: ["escalated"], status_changed_start_time: fiveDaysAgo, status_changed_end_time: now },
  });

  const [shiftView, setShiftView] = useState("current");

  const items = [
    { id: "active", label: t(`Active Alerts`) },
    { id: "assigned", label: t(`Assigned to Me`) },
  ];

  const isEscalationsTileActive =
    location.pathname.includes("overview/escalations") &&
    checkForPresetMatch(status_changed_start_time, status_changed_end_time) === "Last 5 Days";

  function resetToDefault(pathname) {
    const params = Object.entries(defaultAlertsTabFilters).filter(([key, value]) => (value ? { [key]: value } : null));
    navigate({ pathname, search: createSearchParams(Object.fromEntries(params)).toString() });
  }

  function handleTileClick(card, type, filter) {
    const pathname = `overview/${card}`;
    // "Assigned to Me" tile
    if (filter === "assigned") {
      return navigate({ pathname, search: createSearchParams({ assignee_id: [yourUserId], [type]: ["inprogress"] }).toString() });
    }
    if (filter === "active") {
      return navigate({ pathname, search: createSearchParams({ [type]: ["new", "inprogress", "escalated"] }).toString() });
    }
    // All other tiles
    if ([type]?.includes(filter) && [type]?.length === 1) return resetToDefault(pathname);

    return navigate({ pathname, search: createSearchParams({ [type]: filter }).toString() });
  }

  return (
    <StyledOverviewTab>
      {isSidepanelCollapsed ? (
        ["buildings", "warning", "ticket far", "user-shield far"].map((icon) => {
          return (
            <Tile
              key={icon}
              className="collapsed-filter-tile"
              icon={icon}
              onClick={() => dispatch(setIsSidepanelCollapsed(false))}
              noColorBar
            />
          );
        })
      ) : (
        <>
          <Tile className="time-bar" noColorBar header={<Time />} />
          <ExpandableCard className="inner-card" title={t(`Tenant`)} icon="buildings" expanded={clients.length > 1}>
            <div className="tenant-card-contents">
              <Dropdown displayValue={defaultClientLabel} disabled />
              {clients.length > 1 && (
                <Dropdown label={`${t(`View as`)}:`} items={clients} onSelect={(id) => updateQueryParams({ clientId: id })} hasSearch />
              )}
            </div>
          </ExpandableCard>
          <ExpandableCard expanded icon="warning far" title={t(`Darktrace Managed Threat Detection`)}>
            {items.map((item) => {
              const isStatusActive = status?.length === 1 && status[0] === item.id && !assignee_id;
              const isAssigneeActive = item.id === "assigned" && assignee_id;
              const isActiveActive =
                location.pathname.includes("/alerts") &&
                item.id === "active" &&
                status?.length === 3 &&
                ["new", "inprogress", "escalated"].every((x) => status.includes(x));
              return (
                <StyledTile
                  onClick={() => handleTileClick("alerts", "status", item.id)}
                  noColorBar
                  key={item.id}
                  header={item.label}
                  active={item.id === "assigned" ? isAssigneeActive : item.id === "active" ? isActiveActive : isStatusActive}
                  headerRight={
                    <>
                      {item.id === "active" ? (
                        <>
                          {alertsNotAssigned ? (
                            <TileBadge color={alertStatus.new.color} tooltip={t(`Not Assigned`)} data={alertsNotAssigned} />
                          ) : null}
                          {alertsInProgress ? (
                            <TileBadge color={alertStatus.inprogress.color} tooltip={t(`In Progress`)} data={alertsInProgress} />
                          ) : null}
                        </>
                      ) : null}
                      {item.id === "assigned" && assignedToMe ? (
                        <TileBadge color={alertStatus.inprogress.color} tooltip={t(`In Progress`)} data={assignedToMe} />
                      ) : null}
                      <i className="fas fa-arrow-right"></i>
                    </>
                  }
                />
              );
            })}
          </ExpandableCard>
          <ExpandableCard title={t(`Escalated Alerts`)} expanded icon="flag far">
            <StyledTile
              onClick={() => navigate(escalationsNavigateProps)}
              noColorBar
              header={t(`Alerts Escalated in Last 5 Days`)}
              active={isEscalationsTileActive}
              headerRight={
                <>
                  {escalatedAlerts ? (
                    <TileBadge color={alertStatus.escalated.color} tooltip={t(`Escalated Alerts`)} data={escalatedAlerts} />
                  ) : null}
                  <i className="fas fa-arrow-right"></i>
                </>
              }
            />
          </ExpandableCard>
          <ExpandableCard title={t(`Security Operations Support`)} icon="ticket far" expanded>
            {items.map((item) => {
              return (
                <StyledTile
                  disabled
                  noColorBar
                  key={item.id}
                  header={item.label}
                  headerRight={
                    <>
                      <i className="fas fa-arrow-right"></i>
                    </>
                  }
                />
              );
            })}
          </ExpandableCard>
          {isInternalSOCManager ? (
            <Card
              title={t(`Analysts`)}
              icon="user-shield far"
              titleRight={<ShiftButtonGroup active={shiftView} handover={analystShifts?.handover} setActive={setShiftView} />}
            >
              <AnalystList
                activeId={queryParams.analystId}
                onClick={(id) => navigate({ pathname: `overview/analysts`, search: createSearchParams({ analystId: id }).toString() })}
                {...{ shiftView, analystShifts }}
              />
            </Card>
          ) : null}
        </>
      )}
    </StyledOverviewTab>
  );
}
