import React, { useEffect, useMemo, useState } from "react";
import styled from "styled-components";
import { availableRegions, defaultAlertsTabFilters, isValidHostname, now } from "../utils";
import {
  Button,
  CalendarRange,
  Dropdown,
  getItemsWhere,
  getNumberOfItemsSelected,
  SearchField,
  SliderRange,
  Tooltip,
  useDebouncedEffect,
} from "@darktrace/ui-components";
import dayjs from "dayjs";
import { useActiveClientId, useQueryParams } from "../../../logic/hooks";
// import { useAnalystShifts, useUserProfileInfo } from "../../../logic/api";

const StyledFilters = styled.div`
  @keyframes slideIn {
    from {
      height: 0;
      border-bottom: none;
    }
    to {
      height: 10rem;
      padding-bottom: 2.2rem;
      border-bottom: 1px solid var(--dt-ui-card-border);
    }
  }
  display: grid;
  border-bottom: 1px solid var(--dt-ui-card-border);
  align-items: end;
  padding: 1.6rem;
  gap: 0.8rem;
  grid-template-columns: repeat(3, 1fr);
  height: 10rem;
  overflow: scroll;
  animation: slideIn 0.5s ease-in-out;
`;

const StyledTopBar = styled.div`
  padding: 1.6rem;
  padding-bottom: 0.8rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  .dt-ui-input-search {
    width: 100%;
  }
  .button-wrapper {
    justify-content: end;
    display: flex;
    gap: 0.8rem;
  }
`;

export function AlertFilters({ isFiltersVisible, setIsFiltersVisible }) {
  const activeClientId = useActiveClientId();
  // const { data: userProfileInfo } = useUserProfileInfo();
  // const yourUserId = userProfileInfo?.user?.id;

  const { queryParams, updateQueryParams } = useQueryParams({
    defaultQueryParams: {
      clientId: activeClientId,
      ...defaultAlertsTabFilters,
    },
  });
  // const { data: analystShifts } = useAnalystShifts({ clientId: activeClientId, userId: yourUserId, enabled: !!yourUserId });

  // const currentShift = analystShifts?.current;
  const status = queryParams.status?.split(",") || null;
  const client_type = queryParams.client_type?.split(",") || null;
  const region = queryParams.region?.split(",") || null;
  const hostname = queryParams.hostname || null;
  const assignee_id = queryParams.assignee_id?.split(",") || null;
  const start_time = queryParams.start_time || null;
  const end_time = queryParams.end_time || null;
  const min_score = queryParams.min_score || 0;
  const max_score = queryParams.max_score || 100;

  const [startDate, setStartDate] = useState(start_time);
  const [endDate, setEndDate] = useState(end_time);
  const [searchValue, setSearchValue] = useState(hostname || "");
  const [searchTooltip, setSearchTooltip] = useState("");
  const [minSeverity, setMinSeverity] = useState(min_score);
  const [maxSeverity, setMaxSeverity] = useState(max_score);

  const dropdowns = useMemo(() => {
    return [
      {
        id: "client_type",
        title: t(`Type`),
        items: [
          { label: t("Contract"), id: "contract" },
          { label: t("Trial"), id: "trial" },
        ].map(({ id, label }) => ({
          id,
          label,
          selected: client_type?.includes(id),
          // disabled: types?.length === 1 && types[0] === id,
        })),
        onSelect: (value) => handleSelect(client_type, "client_type", value),
        onSelectAll: () => handleSelectAll("client_type", []),
        disableSelectAll: client_type?.length === 2,
      },
      {
        id: "region",
        title: t(`Region`),
        items: availableRegions.map((x) => ({ id: x, label: x, selected: region?.includes(x) })),
        onSelect: (value) => handleSelect(region, "region", value),
        onSelectAll: () => handleSelectAll("region", availableRegions),
        disableSelectAll: region?.length === 4,
      },
      {
        id: "assigned",
        title: t(`Assigned To`),
        // disabled: !currentShift,
        disabled: true,
        items: [],
        // items:
        //   currentShift?.map(({ id, name, email }) => ({
        //     id,
        //     label: name,
        //     description: email,
        //     selected: assignee_id?.includes(email),
        //   })) || [],
        onSelect: (value) => handleSelect(assignee_id, "assignee_id", [value]),
        onSelectAll: () => handleSelectAll("assignee_id", []),
      },
      {
        id: "status",
        title: t(`Status`),
        items: [
          { label: t(`Not Assigned`), id: "new" },
          { label: t(`In Progress`), id: "inprogress" },
          { label: t(`Alert Escalated`), id: "escalated" },
          { label: t(`Resolved`), id: "resolved" },
        ].map(({ id, label }) => ({
          id,
          label,
          selected: status?.includes(id),
          disabled: status?.length === 1 && status[0] === id,
        })),
        onSelect: (value) => handleSelect(status, "status", value),
        onSelectAll: () => handleSelectAll("status", ["new", "inprogress", "escalated", "resolved"]),
        disableSelectAll: status.length === 4,
      },
      {
        id: "severity",
        title: t(`Severity`),
        items: [
          {
            id: "slider",
            label: (
              <SliderRange
                onMinChange={(val) => setMinSeverity(val)}
                onMaxChange={(val) => setMaxSeverity(val)}
                minValue={minSeverity}
                maxValue={maxSeverity}
                showNumbers
              />
            ),
          },
        ],
        checkbox: false,
        closeOnSelect: false,
      },
    ];
  }, [queryParams]);

  function resetFilters() {
    setSearchValue("");
    setSearchTooltip("");
    updateQueryParams(defaultAlertsTabFilters);
    setStartDate();
    setEndDate();
  }

  function handleSelect(queryParamVal, dropdownId, selectedValue) {
    const newFilters =
      !queryParamVal || !queryParamVal?.length || !Array.isArray(queryParamVal)
        ? // Only filter applied
          selectedValue
        : queryParamVal.find((x) => x === selectedValue)
          ? // Remove filter
            queryParamVal.filter((x) => x !== selectedValue)
          : // Add filter
            [queryParamVal, selectedValue].flat();
    updateQueryParams({ [dropdownId]: newFilters });
  }

  function handleSelectAll(type, allItems) {
    return updateQueryParams({ [type]: allItems });
  }

  function getFilterDisplayValue({ title, items, checkbox = true }) {
    if (title === "Severity") return `Severity: ${min_score} - ${max_score}`;
    if (!checkbox) {
      const selectedItems = getItemsWhere(items, (item) => item.selected);
      const selectedItemLabel = selectedItems[0]?.label;
      return `${title}: ${selectedItemLabel}`;
    }

    const numItemsSelected = getNumberOfItemsSelected(items);
    const areAllSelected = numItemsSelected === items.length;
    if (numItemsSelected === 0) return undefined;
    else if (numItemsSelected === 1) return `${title}: 1 selected`;
    else return `${title}: ${areAllSelected ? "All" : numItemsSelected} selected`;
  }

  function handleSearchChange(newValue) {
    setSearchValue(newValue);
  }

  function updateHomenameQuery(searchTerm) {
    if (isValidHostname(searchTerm) || !searchTerm) {
      setSearchTooltip("");
    } else {
      setSearchTooltip(t(`Invalid hostname`));
      return;
    }
    const newValue = !searchTerm && queryParams.hostname ? null : searchTerm;

    updateQueryParams({ hostname: newValue });
  }

  function resetDates() {
    setStartDate(null);
    setEndDate(null);
  }

  useDebouncedEffect(() => updateHomenameQuery(searchValue), 2000, [searchValue]);
  useDebouncedEffect(() => updateQueryParams({ min_score: minSeverity, max_score: maxSeverity }), 500, [minSeverity, maxSeverity]);

  useEffect(() => {
    if (!endDate || !startDate) return;
    const startToString = dayjs(startDate).format("YYYY-MM-DDTHH:mm:ss") + ".000000Z";
    const endToString = dayjs(endDate).format("YYYY-MM-DDTHH:mm:ss") + ".000000Z";
    updateQueryParams({ start_time: startToString, end_time: endToString });
  }, [endDate]);

  // useEffect(() => {
  //   if (assignee_id === null && currentShift) {
  //     updateQueryParams({ assignee_id: currentShift?.map(({ id }) => id).join(",") || null });
  //   }
  // }, [currentShift]);

  return (
    <>
      <StyledTopBar>
        <Tooltip text={searchTooltip}>
          <span>
            <SearchField placeholder={t(`Hostname`)} value={searchValue} onChange={handleSearchChange} />
          </span>
        </Tooltip>
        <span className="button-wrapper">
          <Button variant="secondary" icon="filters far" onClick={() => setIsFiltersVisible((prev) => !prev)}>
            {`${isFiltersVisible ? t(`Hide`) : t(`View`)} ${t(` Filters`)}`}
          </Button>
          <Button variant="secondary" icon="sync" onClick={resetFilters}>
            {t(`Reset`)}
          </Button>
        </span>
      </StyledTopBar>
      {isFiltersVisible ? (
        <StyledFilters>
          {dropdowns.map(({ id, title, checkbox = true, ...options }) => (
            <span key={id}>
              <Dropdown
                placeholder={`${title}: ${t(`No items selected`)}`}
                displayValue={getFilterDisplayValue({ title, items: options.items })}
                checkbox={checkbox}
                {...options}
              />
            </span>
          ))}
          <CalendarRange
            onClose={() => (!startDate || !endDate ? resetDates() : null)}
            selectedStartDate={startDate}
            onSelectStartDate={(date) => setStartDate(date)}
            selectedEndDate={endDate}
            onSelectEndDate={(date) => setEndDate(date)}
            maxDate={now}
            placeholder={t(`Creation Date: All Time`)}
            timezone="UTC"
          />
        </StyledFilters>
      ) : null}
    </>
  );
}
