import React, { Fragment, useEffect, useState, useTransition } from "react";
import styled from "styled-components";
import { Button, Dropdown, ModalCard, Section } from "@darktrace/ui-components";
import {
  useEditParentClientUserGroupsMutation,
  useEditUserGroupsMutation,
  useMultiInstanceProductGroups,
  useMultiInstanceUserProductGroups,
  useProductAccessInstances,
} from "../../logic/api.js";
import { useSelector } from "react-redux";
import { useActiveClientId, useMutliClientUserAccessFlags } from "../../logic/hooks.js";
import { UserInfoSubheader } from "./UserInfoSubheader.jsx";
import { UnlinkUserButton } from "./UnlinkUserButton.jsx";
import { LinkUserButton } from "./LinkUserButton.jsx";

const StyledModalCard = styled(ModalCard)`
  .dt-ui-modal-card {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-height: 90vh;
    width: min(70%, 80rem);

    .dt-ui-card__contents {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background-color: var(--dt-ui-field-bg);
      flex-grow: 1;
      height: 100%;

      .assign-products-modal-content {
        display: flex;
        flex-direction: column;
        gap: 2rem;
        flex-grow: 1;
        max-height: 70rem;

        .tenant-selection {
          display: flex;
          flex-direction: column;
          gap: 1.2rem;
        }

        .product-selection {
          display: flex;
          flex-direction: column;
          gap: 1.2rem;
          overflow-y: auto;

          .products-grid {
            display: grid;
            align-items: center;
            gap: 0.8rem;
            grid-template-columns: 1fr 1fr auto;

            .instance-details {
              display: flex;
              flex-direction: column;

              .instance-subname {
                font-size: 1rem;
                color: var(--dt-ui-text-color-muted);
              }
            }
          }
        }

        .action-buttons {
          display: flex;
          padding: 1.2rem 0.8rem;
          gap: 0.8rem;

          .dt-ui-button {
            flex: 1;
          }
        }
      }
    }
  }
`;

export function AssignProductsModal({ open, user = {}, onClose = () => {}, isYou }) {
  const modalRoot = document.querySelector("#modal-root");
  const activeClientId = useActiveClientId();
  const defaultClientId = useSelector((state) => state.app.defaultClientId);
  const [selectedClientId, setSelectedClientId] = useState(activeClientId);
  const [isPendingTransition, startTransition] = useTransition(); // eslint-disable-line

  useEffect(() => {
    startTransition(() => setSelectedClientId(activeClientId));
  }, [activeClientId]);

  const { id: userId, name, email, clientId: userClientId } = user;

  // get clients that current logged in user is admin on
  const { data: multiClientUserAccessFlags } = useMutliClientUserAccessFlags();
  const availableUserManagementClientIds = multiClientUserAccessFlags
    .filter(({ flags }) => flags.includes("user-management"))
    .map(({ clientId }) => clientId);

  const clients = useSelector((state) => state.app.clients) ?? [];
  const availableUserManagementClients = clients
    .filter((client) => {
      if (user?.clientId === defaultClientId) return availableUserManagementClientIds.includes(client.id);
      else return user?.clientId === client.id;
    })
    .map((client) => ({ ...client, selected: client.id === selectedClientId }));

  // get product access instances that CLIENT has access to
  const { data: clientProductAccessInstances = [] } = useProductAccessInstances({
    clientId: selectedClientId,
    enabled: !!open,
  });

  const sortedProductAccessInstances = clientProductAccessInstances.toSorted((instanceA, instanceB) => {
    if (instanceA.type === "sabre-threat-visualiser-product-access") return 1;
    if (instanceB.type === "sabre-threat-visualiser-product-access") return -1;
    return 0;
  });

  const instancesThatAreDown = clientProductAccessInstances
    .filter((instance) => instance.state !== "UP")
    .filter((instance) => instance.type === "sabre-threat-visualiser-product-access"); // TODO: this should not need to filter by type if API returns a state for all instances

  const isSelectedClientTheUsersDefaultClient = userClientId && selectedClientId === userClientId;
  // const isUserFromDarktraceInternalClient =
  //   userClientId && availableUserManagementClients.find((client) => client.isDarktrace)?.id === userClientId;

  const {
    data: allProductGroups,
    instancesLoadingProductGroups,
    instancesFailingToLoadProductGroups,
  } = useMultiInstanceProductGroups({ clientId: activeClientId, state: "UP", enabled: open });

  // get product access instances that USER has access to
  const {
    userProductGroupIds: initialUserProductGroupIds,
    userLinkedNames: instanceUserLinks,
    // isLoadingUserProductGroupIds,
    instancesLoadingUserProductGroups,
    instancesFailingToLoadUserProductGroupIds,
  } = useMultiInstanceUserProductGroups({ clientId: activeClientId, email, userClientId, userId, enabled: !!open, state: "UP" });

  const [localUserProductGroupIds, setLocalUserProductGroupIds] = useState([]);
  const initialUserProductGroupIdsHash = initialUserProductGroupIds.map(({ groupId, instanceId }) => `${groupId}:${instanceId}`).join("-");

  useEffect(() => {
    startTransition(() => setLocalUserProductGroupIds(initialUserProductGroupIds));
  }, [initialUserProductGroupIdsHash]);

  const instanceGroupIdsToAdd = localUserProductGroupIds.filter(
    ({ instanceId, groupId }) =>
      !initialUserProductGroupIds.some(({ instanceId: iId, groupId: gId }) => instanceId === iId && groupId === gId),
  );
  const instanceGroupIdsToRemove = initialUserProductGroupIds.filter(
    ({ instanceId, groupId }) =>
      !localUserProductGroupIds.some(({ instanceId: iId, groupId: gId }) => instanceId === iId && groupId === gId),
  );

  const hasChanges = instanceGroupIdsToAdd.length > 0 || instanceGroupIdsToRemove.length > 0;

  const { mutate: editUserGroupsMutation, isPending: isUserGroupsPending } = useEditUserGroupsMutation();
  const { mutate: editParentClientUserGroupsMutation, isPending: isParentClientUserGroupsPending } =
    useEditParentClientUserGroupsMutation();

  const mutate = isSelectedClientTheUsersDefaultClient ? editUserGroupsMutation : editParentClientUserGroupsMutation;
  const isPending = isSelectedClientTheUsersDefaultClient ? isUserGroupsPending : isParentClientUserGroupsPending;

  function handleSaveUserProducts() {
    const instanceIdsWithAdditions = instanceGroupIdsToAdd.map(({ instanceId }) => instanceId);
    const instanceIdsWithRemovals = instanceGroupIdsToRemove.map(({ instanceId }) => instanceId);

    const instanceIdsWithChanges = [...instanceIdsWithAdditions, ...instanceIdsWithRemovals].reduce((prevInstanceIds, instanceId) => {
      if (!prevInstanceIds.includes(instanceId)) prevInstanceIds.push(instanceId);
      return prevInstanceIds;
    }, []);

    instanceIdsWithChanges.forEach((instanceId) => {
      const add = instanceGroupIdsToAdd.filter(({ instanceId: iId }) => instanceId === iId).map(({ groupId }) => groupId);
      const remove = instanceGroupIdsToRemove.filter(({ instanceId: iId }) => instanceId === iId).map(({ groupId }) => groupId);

      mutate({ username: email, userId, instanceId, add, remove });
    });

    onClose();
  }

  function handleSelectGroup(groupId, instanceId) {
    setLocalUserProductGroupIds((prevLocalUserProductGroupIds) => {
      if (prevLocalUserProductGroupIds.some(({ groupId: gId, instanceId: iId }) => groupId === gId && instanceId === iId)) {
        return prevLocalUserProductGroupIds.filter(({ groupId: gId, instanceId: iId }) => groupId !== gId || instanceId !== iId);
      } else {
        return [...prevLocalUserProductGroupIds, { groupId, instanceId }];
      }
    });
  }

  function handleSelectAllGroups(instanceId) {
    setLocalUserProductGroupIds((prevLocalUserProductGroupIds) => {
      const allInstanceGroupIds = allProductGroups
        .filter(({ instanceId: iId }) => instanceId === iId)
        .map(({ id, instanceId: iId }) => ({ groupId: id, instanceId: iId }));
      const areAllInstanceGroupsAlreadySelected =
        prevLocalUserProductGroupIds.filter(({ instanceId: iId }) => instanceId === iId).length === allInstanceGroupIds.length;

      const filteredGroupIds = prevLocalUserProductGroupIds.filter(({ instanceId: iId }) => instanceId !== iId);
      if (areAllInstanceGroupsAlreadySelected) return filteredGroupIds;
      else return [...filteredGroupIds, ...allInstanceGroupIds];
    });
  }

  return (
    <StyledModalCard
      keepMounted={false}
      open={open}
      subheaders={[{ size: "large", component: <UserInfoSubheader name={name} email={email} isYou={isYou} /> }]}
      modalRoot={modalRoot}
      title={`Assign Products`}
      onClose={onClose}
      closeOnClickBackdrop={false}
    >
      <div className="assign-products-modal-content">
        {availableUserManagementClients.length > 1 && (
          <div className="tenant-selection">
            <Section title="Tenant" />
            <Dropdown
              className="client-dropdown"
              items={availableUserManagementClients}
              onSelect={(clientId) => setSelectedClientId(clientId)}
              hasSearch
            />
          </div>
        )}
        <div className="product-selection">
          <Section title="Products" />
          <div className="products-grid">
            {sortedProductAccessInstances.map((accessInstance) => {
              const instanceId = accessInstance.instanceId;
              const instanceGroups = allProductGroups.filter((group) => group.instanceId === instanceId);

              const dropdownItems = instanceGroups.map((group) => {
                const localInstanceGroupIds = localUserProductGroupIds
                  .filter(({ instanceId: groupInstanceId }) => instanceId === groupInstanceId)
                  .map(({ groupId }) => groupId);

                const isGroupSelected = localInstanceGroupIds.includes(group.id);

                return {
                  id: group.id,
                  label: group.displayName,
                  selected: isGroupSelected,
                };
              });

              const isSabreInstance = accessInstance.type === "sabre-threat-visualiser-product-access";
              // const userHasDarktraceAccessToProduct = isUserFromDarktraceInternalClient && isSabreInstance;
              const userHasDarktraceAccessToProduct = false; // TODO

              const isCustomerPortalInstance = accessInstance.type === "customer-portal-product-access";

              const hasAnotherInstanceWithSameName = clientProductAccessInstances.some(
                (instance) => instance.instanceId !== instanceId && instance.displayName === accessInstance.displayName,
              );

              const isInstanceDown = instancesThatAreDown.some((instance) => instance.instanceId === instanceId);
              const linkedSabreUsername = instanceUserLinks.find(({ instanceId: iId }) => instanceId === iId)?.linkedName;

              return (
                <Fragment key={accessInstance.instanceId}>
                  <div className="instance-details">
                    <span className="instance-name">{accessInstance.displayName}</span>
                    {hasAnotherInstanceWithSameName && <span className="instance-subname">{accessInstance.instanceId}</span>}
                  </div>

                  <UserProductStatus
                    userId={userId}
                    email={email}
                    userClientId={userClientId}
                    instanceId={instanceId}
                    isInstanceDown={isInstanceDown}
                    isSabreInstance={isSabreInstance}
                    linkedSabreUsername={linkedSabreUsername}
                    userHasDarktraceAccessToProduct={userHasDarktraceAccessToProduct}
                    isLoadingLinkedSabreUsername={instancesLoadingUserProductGroups.includes(instanceId)}
                  />

                  <Dropdown
                    checkbox
                    items={dropdownItems}
                    onSelect={(groupId) => handleSelectGroup(groupId, instanceId)}
                    onSelectAll={() => handleSelectAllGroups(instanceId)}
                    disabled={
                      isInstanceDown ||
                      (isSabreInstance && !linkedSabreUsername) ||
                      (isCustomerPortalInstance && !isSelectedClientTheUsersDefaultClient) ||
                      userHasDarktraceAccessToProduct ||
                      instancesLoadingProductGroups.includes(instanceId) ||
                      instancesLoadingUserProductGroups.includes(instanceId) ||
                      instancesFailingToLoadProductGroups.includes(instanceId) ||
                      instancesFailingToLoadUserProductGroupIds.includes(instanceId)
                    }
                    displayValue={
                      isInstanceDown
                        ? t(`Unable to contact product`)
                        : userHasDarktraceAccessToProduct
                          ? t(`User has full access to this product`)
                          : isCustomerPortalInstance && !isSelectedClientTheUsersDefaultClient
                            ? t(`Product unavailable for this user`)
                            : instancesFailingToLoadProductGroups.includes(instanceId) ||
                                instancesFailingToLoadUserProductGroupIds.includes(instanceId)
                              ? t(`Unable to fetch user product groups`)
                              : instancesLoadingProductGroups.includes(instanceId) || instancesLoadingUserProductGroups.includes(instanceId)
                                ? t(`Loading...`)
                                : isSabreInstance && !linkedSabreUsername
                                  ? "User is not linked"
                                  : undefined
                    }
                  />
                </Fragment>
              );
            })}
          </div>
        </div>

        <div className="action-buttons">
          <Button variant="secondary" onClick={onClose}>
            Cancel
          </Button>
          <Button loading={isPending} disabled={!hasChanges} onClick={handleSaveUserProducts}>
            Assign to User
          </Button>
        </div>
      </div>
    </StyledModalCard>
  );
}

const StyledUserProductStatus = styled.div`
  display: flex;
  justify-content: flex-end;
`;

function UserProductStatus({
  userId,
  email,
  userClientId,
  instanceId,
  isInstanceDown,
  isSabreInstance,
  linkedSabreUsername,
  userHasDarktraceAccessToProduct,
  isLoadingLinkedSabreUsername,
}) {
  if (!isSabreInstance) return <span />;
  else if (isInstanceDown) return <span />;
  else if (userHasDarktraceAccessToProduct) return <span />;
  else
    return (
      <StyledUserProductStatus>
        {isLoadingLinkedSabreUsername ? (
          <span>{t(`Loading...`)}</span>
        ) : linkedSabreUsername ? (
          <UnlinkUserButton instanceId={instanceId} linkedSabreUsername={linkedSabreUsername} userId={userId} />
        ) : (
          <LinkUserButton instanceId={instanceId} userId={userId} email={email} userClientId={userClientId} />
        )}
      </StyledUserProductStatus>
    );
}
