import React, { useCallback, useRef, useState } from "react";
import styled from "styled-components";
import { GhostElement, TileContainer } from "@darktrace/ui-components";
import { Alert } from "./AlertTile.jsx";
import { usePTNAlerts } from "../../../logic/api.js";
import { defaultAlertsTabFilters } from "../utils.js";
import { useQueryParams } from "../../../logic/hooks.js";
import { useActiveClientId } from "../../../logic/hooks.js";
import { AlertFilters } from "./AlertFilters.jsx";

const StyledPTNAlerts = styled.div`
  overflow: hidden;
  height: 100%;
  .dt-ui-tile-container {
    padding: 1.6rem;
    height: ${(props) => (props.$isFiltersOpen ? "calc(100% - 15.2rem)" : "calc(100% - 5.2rem)")};
    overflow: scroll;
    .alerts-wrapper {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
    }
    .dt-ui-ghost-element {
      height: 100%;
    }
    .infinite-scroll-text {
      text-align: center;
    }
  }
`;

export function PTNAlerts() {
  const activeClientId = useActiveClientId();
  const [isFiltersVisible, setIsFiltersVisible] = useState(false);
  const { queryParams } = useQueryParams({
    defaultQueryParams: { clientId: activeClientId, ...defaultAlertsTabFilters },
  });

  const status = queryParams.status?.split(",") || null;
  const client_type = queryParams.client_type?.split(",") || null;
  const region = queryParams.region?.split(",") || null;
  const hostname = queryParams.hostname || null;
  const assignee_id = queryParams.assignee_id?.split(",") || null;
  const start_time = queryParams.start_time || null;
  const end_time = queryParams.end_time || null;
  const min_score = queryParams.min_score || 0;
  const max_score = queryParams.max_score || 100;

  const {
    data: alerts,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = usePTNAlerts({
    clientId: activeClientId,
    filters: { status, hostname, client_type, region, assignee_id, start_time, end_time, min_score, max_score },
  });

  // Infinite scrolling logic
  const observerRef = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (isLoading || isFetchingNextPage) return;
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });
      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage],
  );

  return (
    <StyledPTNAlerts id="ptn-alerts" $isFiltersOpen={isFiltersVisible}>
      <AlertFilters {...{ isFiltersVisible, setIsFiltersVisible }} />
      <TileContainer>
        {isLoading ? (
          <GhostElement />
        ) : isError || !alerts?.pages[0] ? (
          <div>{t(`Failed to load alerts.`)}</div>
        ) : (
          <>
            {!alerts?.pages[0]?.data.length ? (
              <div>{t(`No alerts to show.`)}</div>
            ) : (
              alerts.pages.map((page, pageIndex) => (
                <div className="alerts-wrapper" key={pageIndex}>
                  {page.data.map((alert) => (
                    <Alert key={alert.id} {...{ alert }} />
                  ))}
                </div>
              ))
            )}
            <div className="infinite-scroll-text" ref={lastElementRef}>
              {hasNextPage || alerts.pages[0].last_page === 1 ? "" : t(`No more results.`)}
            </div>
          </>
        )}
      </TileContainer>
    </StyledPTNAlerts>
  );
}
