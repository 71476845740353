import React, { useCallback, useRef } from "react";
import styled from "styled-components";
import { usePTNAlerts } from "../../../logic/api";
import { useActiveClientId } from "../../../logic/hooks";
import { GhostElement, Tile, TileContainer } from "@darktrace/ui-components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EscalationCard } from "./EscalationCard.jsx";
import dayjs from "dayjs";

const StyledEscalations = styled.div`
  height: 100%;
  .dt-ui-tile-container {
    height: 100%;
    .dt-ui-ghost-element {
      height: 100%;
    }
    .wrapper {
      display: flex;
      flex-direction: column;
      gap: 0.8rem;
      .dt-ui-tile__body-left {
        display: grid;
        grid-template-columns: 1fr 1fr auto;
        justify-content: space-evenly;
        align-items: center;
        > div {
          display: flex;
          flex-direction: column;
        }
      }
    }
  }
`;

const StyledTile = styled(Tile)``;

export function Escalations() {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const activeClientId = useActiveClientId();

  // NOTE: Don't use toISOString()
  const now = dayjs().utc().format("YYYY-MM-DDTHH:mm:ss") + ".000000Z";
  const fiveDaysAgo = dayjs().utc().subtract(5, "day").format("YYYY-MM-DDTHH:mm:ss") + ".000000Z";

  const {
    data: escalatedAlerts,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = usePTNAlerts({
    clientId: activeClientId,
    filters: { statuses: ["escalated"], status_changed_start_time: fiveDaysAgo, status_changed_end_time: now },
  });

  console.log(escalatedAlerts);

  // Infinite scrolling logic
  const observerRef = useRef();
  const lastElementRef = useCallback(
    (node) => {
      if (isLoading || isFetchingNextPage) return;
      if (observerRef.current) observerRef.current.disconnect();
      observerRef.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasNextPage) {
          fetchNextPage();
        }
      });
      if (node) observerRef.current.observe(node);
    },
    [isFetchingNextPage, fetchNextPage, hasNextPage],
  );

  return (
    <StyledEscalations>
      {id ? (
        <EscalationCard />
      ) : (
        <TileContainer>
          {isLoading ? (
            <GhostElement />
          ) : isError || !escalatedAlerts?.pages[0] ? (
            <div>{t(`Failed to load alerts.`)}</div>
          ) : (
            <>
              {escalatedAlerts.pages.map((page, pageIndex) => (
                <div className="wrapper" key={pageIndex}>
                  {page.data.map((alert) => (
                    <StyledTile
                      accentColor="var(--dt-ui-critical)"
                      key={alert.id}
                      onClick={() => navigate(`${location.pathname}/${alert.id}`)}
                    >
                      <div>
                        <span>{alert.model_name}</span>
                        <span>#{alert.id}</span>
                      </div>
                      <div>
                        <span>Alert Escalated:</span>
                        <span>{alert.status_changed}</span>
                      </div>
                      <i className="fas fa-arrow-right"></i>
                    </StyledTile>
                  ))}
                </div>
              ))}
              <div className="infinite-scroll-text" ref={lastElementRef}>
                {hasNextPage || escalatedAlerts.pages[0].last_page === 1 ? "" : t(`No more results.`)}
              </div>
            </>
          )}
        </TileContainer>
      )}
    </StyledEscalations>
  );
}
