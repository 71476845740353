import React from "react";
import dayjs from "dayjs";
import { t } from "./../../logic/translator.js";
import { createSearchParams, Navigate, Route } from "react-router-dom";

// ------------------------------ Pages and Routing

export const cloudSOCPages = [
  {
    id: "overview",
    children: ["alerts", "escalations", "tickets", "analysts"],
    element: <Navigate to="overview/alerts" />,
  },
  { id: "alerts" },
  { id: "escalations", children: [":id"] },
  { id: "analysts" },
  { id: ":id", fallback: "/dashboard" },
  { id: "tickets" },
  { id: "settings", children: ["option1"] },
  { id: "option1" },
];

export function getCloudSOCPages({ userOwnRoles }) {
  return cloudSOCPages.map((page) => {
    if (userOwnRoles === undefined) return { ...page, available: undefined };

    let isPageAvailable = false;
    if (page.id === "overview") isPageAvailable = true;
    if (page.id === "alerts") isPageAvailable = true;
    if (page.id === "escalations") isPageAvailable = true;
    if (page.id === ":id") isPageAvailable = true;
    if (page.id === "tickets") isPageAvailable = true;
    if (page.id === "analysts") isPageAvailable = !!userOwnRoles.find((role) => role.id === "internal-client-role-cloud-soc-manager");
    if (page.id === "settings") isPageAvailable = !!userOwnRoles.find((role) => role.id === "internal-client-role-cloud-soc-manager");
    if (page.id === "option1") isPageAvailable = !!userOwnRoles.find((role) => role.id === "internal-client-role-cloud-soc-manager");

    return { ...page, available: isPageAvailable };
  });
}

export function renderCloudSOCRoutes(page, depth = 0, maxDepth = 2) {
  // Increase maxDepth if need for deeper route nesting
  if (depth > maxDepth) return;
  if (!page) return null;

  return (
    <Route key={page.id} path={page.id} element={page.element || null}>
      {page.children?.map((childId) => {
        const childPage = cloudSOCPages.find((child) => child.id === childId);
        return renderCloudSOCRoutes(childPage, depth + 1);
      })}
    </Route>
  );
}

// ------------------------------ Alerts

export const alertStatus = {
  new: { label: t(`Not Assigned`), color: "var(--dt-ui-resolved-200)" },
  inprogress: { label: t(`In Progress`), color: "var(--dt-ui-medium-100)" },
  escalated: { label: t(`Alert Escalated`), color: "var(--dt-ui-critical-300)" },
  resolved: { label: t(`Resolved`), color: "var(--dt-ui-polar-glacier-200)" },
};

export const availableRegions = ["US", "CAN", "EMEA", "APAC"];

export const defaultAlertsTabFilters = {
  status: ["new", "inprogress", "escalated"].join(","),
  hostname: null,
  client_type: ["contract", "trial"].join(","),
  region: availableRegions.join(","),
  assignee_id: null,
  min_score: 0,
  max_score: 100,
  status_changed_start_time: null,
  status_changed_end_time: null,
  start_time: null,
  end_time: null,
};

// NOTE: Don't use toISOString()
export const now = dayjs().utc().second("00").format("YYYY-MM-DDTHH:mm:ss") + ".000000Z";
export const startofToday = dayjs().utc().second("00").startOf("day").format("YYYY-MM-DDTHH:mm:ss") + ".000000Z";
export const endOfToday = dayjs().utc().second("00").endOf("day").format("YYYY-MM-DDTHH:mm:ss") + ".000000Z";
export const fiveDaysAgo = dayjs().utc().second("00").subtract(5, "day").startOf("day").format("YYYY-MM-DDTHH:mm:ss") + ".000000Z";

export const escalationsNavigateProps = {
  pathname: "overview/escalations",
  search: createSearchParams({ status_changed_start_time: fiveDaysAgo, status_changed_end_time: now }).toString(),
};

export const defaultEscalatedTabFilters = {
  status: ["escalated"].join(","),
  status_changed_start_time: fiveDaysAgo,
  status_changed_end_time: now,
};

export const isValidHostname = (val) => {
  let pattern = /^[a-z]+[0-9]?-[0-9]+-[0-9]*/;
  return pattern.test(val);
};

export function checkForPresetMatch(start, end) {
  if (!start || !end) return null;
  const startFormatted = dayjs(start).utc();
  const endFormatted = dayjs(end).utc();
  if (endFormatted.format("YYYY-MM-DD") !== dayjs().utc().format("YYYY-MM-DD")) return null;
  const diff = Math.abs(Math.floor(startFormatted.diff(endFormatted, "days")));
  const duration = diff === 1 ? 24 : [3, 5, 7].includes(diff) ? diff : Math.round(diff / 7);
  const unit = [3, 5, 7].includes(diff) ? "Days" : diff === 1 ? "Hours" : "Weeks";
  return `Last ${duration} ${unit}`;
}

// ------------------------------ Analyst Overview

export function formatShifts(rawShifts) {
  if (!rawShifts) return;
  const { isCurrentUserOnShift, handover, isUsShift, slot } = rawShifts;
  let current = [];
  let next = [];
  const formattedShift = ["contract", "pov"].map((x) => {
    current.push(
      rawShifts[x].current.map((y) => {
        return { ...y, type: x };
      }),
    );
    next.push(
      rawShifts[x].next.map((y) => {
        return { ...y, type: x };
      }),
    );
    return {
      current: current.flat().sort((a, b) => (a.name < b.name ? -1 : 1)),
      next: next.flat().sort((a, b) => (a.name < b.name ? -1 : 1)),
    };
  });
  return { handover, isCurrentUserOnShift, isUsShift, slot, ...Object.assign({}, ...formattedShift) };
}
